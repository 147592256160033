// react
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMaterialUIController, validateRole, setOpenMarketMessage } from "context";
import { useQueries } from "@tanstack/react-query";

import http from "services/http";
import httpPut from "services/httpPut";

import MDLoader from "components/MDLoader";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTableRowSelectMarketRequestIII from "examples/Tables/DataTableRowSelectMarketRequestIII";

// Data
import marketsManagementTableData from "layouts/tables/data/marketsManagementTableData";
// Function to find full information in the second array based on the key in the first array
function findFullInformation(firstArray, secondArray) {
  const result = [];

  firstArray.forEach((item) => {
    // eslint-disable-next-line no-underscore-dangle
    const matchedItem = secondArray.find((obj) => obj._id === item);
    if (matchedItem) {
      const fullInformation = { item, ...matchedItem };
      result.push(fullInformation);
    }
  });

  return result;
}

function MarketsManagement() {
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();

  const handleNewMarketRequest = async (e) => {
    e.preventDefault();
    navigate("/Market-Management/Submit-New-Market-Request");
  };

  const { columns1 } = marketsManagementTableData();

  const getMarketInfoRequest = async () => {
    const result = await http({
      path: `/marketinforequest`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const updateManyMarketInfoRequest = async (values) => {
    const result = await httpPut({
      // eslint-disable-next-line no-underscore-dangle
      path: `/marketinforequest/updatemany`,
      method: "PUT",
      raw: JSON.stringify(values),
      accessToken: userData.token,
    });
    return result;
  };

  const [marketInfoRequest] = useQueries({
    queries: [
      {
        queryKey: ["marketInfoRequest"],
        queryFn: getMarketInfoRequest,
      },
    ],
  });

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
    }
  }, [userData]);

  const approveMarketsByTradingVolume = async (itemsToApprove) => {
    const results = [];

    const fullInformationArray = findFullInformation(itemsToApprove, marketInfoRequest.data);

    const filteredArray = fullInformationArray.filter(
      // (obj) => obj.Status === "Approved" || obj.Status === "Pending for Approval"
      (obj) => obj.StatusId === 3 || obj.StatusId === 2
    );
    const ids = filteredArray.map((el) => el.item);

    await Promise.all(
      ids.map(async (element) => {
        // const { StatusId: elementStatusId } = marketInfoRequest.data.filter(
        //   // eslint-disable-next-line no-underscore-dangle
        //   (el) => el._id === element
        // )[0];

        // let nextStatus;
        // switch (elementStatusId) {
        //   // case "Pending for Approval":
        //   case 1:
        //     nextStatus = { ApprovalDate: new Date(), StatusId: 2 };
        //     break;
        //   // case "Approved":
        //   case 2:
        //     nextStatus = { CompletionDate: new Date(), StatusId: 3 };
        //     break;
        //   default:
        //     break;
        // }

        const result = await updateManyMarketInfoRequest({
          id: element,
          IsTradingVolumeEnough: true,
          // ...nextStatus,
        });
        results.push(result);
      })
    );
    const allResultsSuccessful = results.every((result) => result.ok);

    if (allResultsSuccessful) {
      await setOpenMarketMessage(dispatch, {
        open: true,
        color: "success",
        icon: "check",
        title: "Success",
        content: "Request has been updated",
      });
    } else {
      await setOpenMarketMessage(dispatch, {
        open: true,
        color: "error",
        icon: "error",
        title: "Database Error",
        content: "There was an error updating the information",
      });
    }
    marketInfoRequest.refetch();
    // just status is gonna be updated
    // const mergedArray = marketInfoRequest.map((item1) => {
    //   // eslint-disable-next-line no-underscore-dangle
    //   const item2 = itemsToApprove.find((item) => item === item1._id);
    //   if (item2) {
    //     return { ...item1, Status: "Approved" };
    //   }
    //   return { ...item1 };
    // });

    // setMarketInfoRequest(mergedArray);
  };

  const approveMarkets = async (itemsToApprove) => {
    const results = [];

    const fullInformationArray = findFullInformation(itemsToApprove, marketInfoRequest.data);

    const filteredArray = fullInformationArray.filter(
      // (obj) => obj.Status === "Approved" || obj.Status === "Pending for Approval"
      (obj) => obj.StatusId === 3 || obj.StatusId === 2
    );
    const ids = filteredArray.map((el) => el.item);

    await Promise.all(
      ids.map(async (element) => {
        const { StatusId: elementStatusId } = marketInfoRequest.data.filter(
          // eslint-disable-next-line no-underscore-dangle
          (el) => el._id === element
        )[0];

        let nextStatus;
        switch (elementStatusId) {
          // case "Pending for Approval":
          case 2:
            nextStatus = { ApprovalDate: new Date(), StatusId: 3 };
            break;
          // case "Approved":
          case 3:
            nextStatus = { CompletionDate: new Date(), StatusId: 4 };
            break;
          default:
            break;
        }

        const result = await updateManyMarketInfoRequest({
          id: element,
          ...nextStatus,
        });
        results.push(result);
      })
    );
    const allResultsSuccessful = results.every((result) => result.ok);

    if (allResultsSuccessful) {
      await setOpenMarketMessage(dispatch, {
        open: true,
        color: "success",
        icon: "check",
        title: "Success",
        content: "Request has been updated",
      });
    } else {
      await setOpenMarketMessage(dispatch, {
        open: true,
        color: "error",
        icon: "error",
        title: "Database Error",
        content: "There was an error updating the information",
      });
    }
    marketInfoRequest.refetch();
    // just status is gonna be updated
    // const mergedArray = marketInfoRequest.map((item1) => {
    //   // eslint-disable-next-line no-underscore-dangle
    //   const item2 = itemsToApprove.find((item) => item === item1._id);
    //   if (item2) {
    //     return { ...item1, Status: "Approved" };
    //   }
    //   return { ...item1 };
    // });

    // setMarketInfoRequest(mergedArray);
  };

  const rejectMarkets = async (itemsToReject) => {
    const results = [];

    const fullInformationArray = findFullInformation(itemsToReject, marketInfoRequest.data);

    const filteredArray = fullInformationArray.filter(
      // (obj) => obj.Status === "Approved" || obj.Status === "Pending for Approval"
      (obj) => obj.StatusId === 3 || obj.StatusId === 2
    );
    const ids = filteredArray.map((el) => el.item);

    await Promise.all(
      ids.map(async (element) => {
        const result = await updateManyMarketInfoRequest({
          id: element,
          RejectionDate: new Date(),
          // Status: "Rejected",
          StatusId: 5,
        });
        results.push(result);
      })
    );
    const allResultsSuccessful = results.every((result) => result.ok);

    if (allResultsSuccessful) {
      await setOpenMarketMessage(dispatch, {
        open: true,
        content: "Request has been updated",
      });
    } else {
      await setOpenMarketMessage(dispatch, {
        open: true,
        color: "error",
        icon: "error",
        title: "Database Error",
        content: "There was an error updating the information",
      });
    }
    // just status is gonna be updated
    // const mergedArray = marketInfoRequest.map((item1) => {
    //   // eslint-disable-next-line no-underscore-dangle
    //   const item2 = itemsToReject.find((item) => item === item1._id);
    //   if (item2) {
    //     return { ...item1, Status: "Rejected" };
    //   }
    //   return { ...item1 };
    // });

    // setMarketInfoRequest(mergedArray);
    marketInfoRequest.refetch();
  };

  if (marketInfoRequest.isLoading) return <MDLoader />;
  if (marketInfoRequest.isError) return <div>Error at loading market requests</div>;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {marketInfoRequest?.data && marketInfoRequest?.data?.length > 0 ? (
        <DataTableRowSelectMarketRequestIII
          table={{ columns: columns1, rows: marketInfoRequest.data }}
          isSorted
          entriesPerPage
          showTotalEntries
          noEndBorder
          canSearch
          canApprove={(userData && userData.role === "admin") || false}
          approveMarkets={approveMarkets}
          canReject={(userData && userData.role === "admin") || false}
          rejectMarkets={rejectMarkets}
          approveMarketsByTradingVolume={approveMarketsByTradingVolume}
          handleNewMarketRequest={handleNewMarketRequest}
        />
      ) : null}
      <Footer />
    </DashboardLayout>
  );
}

export default MarketsManagement;
