/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
// import MDLink from "components/MDLink";
import { Link } from "react-router-dom";
import MDLink from "components/MDLink";
import MDTypography from "components/MDTypography";

export default function data() {
  return {
    columns: [
      {
        Header: "Name",
        accessor: "Name",
        align: "left",
        maxWidth: 250,
        minWidth: 250,
        Filter: "",
      },
      {
        Header: "PEITicker",
        accessor: "PEITicker",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
        Filter: "",
      },
      {
        Header: "RunRegion",
        accessor: "RunRegion",
        align: "left",
        maxWidth: 75,
        minWidth: 75,
        Filter: "",
      },
      {
        Header: "Category",
        accessor: "Category",
        align: "left",
        maxWidth: 175,
        minWidth: 175,
        Filter: "",
      },
      {
        Header: "PrimarySubCategory",
        accessor: "PrimarySubCategory",
        align: "left",
        maxWidth: 175,
        minWidth: 175,
        Filter: "",
      },
      {
        Header: "Missing Data Points",
        accessor: (d) => d.missingDataPoints?.total,
        align: "center",
        maxWidth: 70,
        minWidth: 70,
        Filter: "",
        // Filter: NumberRangeColumnFilter,
        // filter: "between",
        // isSorted: false,
        Cell: ({ row }) => {
          const url = `/market-price-data-check/mdp/${row.original.PEITicker}`;
          const totalRecords = row.original.missingDataPoints?.total || 0;
          const tf = row.original.TimeFrame;
          if (tf === 1) {
            return (
              <MDTypography
                component="span"
                variant="h7"
                color="info"
                fontWeight="medium"
                sx={{ lineHeight: 0 }}
              >
                -
              </MDTypography>
            );
          }
          if (totalRecords > 0) {
            return (
              <Link to={url} state={{ percentageChangeInfo: row.original }}>
                <MDTypography
                  component="span"
                  variant="h7"
                  color="info"
                  fontWeight="medium"
                  sx={{ lineHeight: 0 }}
                >
                  {totalRecords}
                </MDTypography>
              </Link>
            );
          }
          return (
            <MDTypography
              component="span"
              variant="h7"
              color="info"
              fontWeight="medium"
              sx={{ lineHeight: 0 }}
            >
              {totalRecords}
            </MDTypography>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "Edit",
        Filter: "",
        align: "center",
        maxWidth: 50,
        minWidth: 50,
        Cell: ({ row }) => (
          <MDLink
            to={`/market-price-data-check/mdp/edit-market-price-data-check/${row.original.PEITicker}/timeframe/${row.original.TimeFrame}`}
            text="Edit"
          />
        ),
      },
    ],
  };
}
